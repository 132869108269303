<template>
  <section>
    <div class="row">
      <div class="col-12 mb-2">
        <h1 v-show="!isEditing" class="h3">Añadir nuevo edificio</h1>
      </div>

      <!-- Filter card -->
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <form v-on:submit.prevent="submitGlobal">
              <div class="row">
                <!-- Left -->
                <div class="col-sm-12 col-md-6">
                  <div class="row">
                    <div class="col-12 col-sm-6 form-group">
                      <label for="referenciaCatastral" class="label">
                        Ref. catastral
                        <small class="text-muted">* Obligatorio</small>
                      </label>
                      <input
                        v-model="form.referencia_catastral"
                        type="text"
                        class="form-control"
                        id="referenciaCatastral"
                        required
                        maxlength="20"
                        minlength="20"
                      />
                    </div>

                    <div class="col-12 col-sm-6 form-group">
                      <label for="denominacion" class="label">
                        Denominación
                        <small class="text-muted">* Obligatorio</small>
                      </label>
                      <input
                        v-model="form.denominacion"
                        type="text"
                        class="form-control"
                        id="denominacion"
                        placeholder=""
                        required
                      />
                    </div>

                    <div class="col-12 col-sm-6 form-group">
                      <label for="localidad" class="label">
                        Localidad
                        <small class="text-muted">* Obligatorio</small>
                      </label>
                      <input
                        v-model="form.localidad"
                        type="text"
                        class="form-control"
                        id="localidad"
                        placeholder=""
                        required
                      />
                    </div>

                    <div class="col-12 col-sm-6 form-group">
                      <label for="uso" class="label">
                        Uso <small class="text-muted">* Obligatorio</small>
                      </label>
                      <select
                        required
                        class="custom-select"
                        id="uso"
                        v-model="form.uso"
                      >
                        <option
                          v-for="(option, index) in usoArray"
                          :value="option.value"
                          :key="index"
                        >
                          {{ option.text }}
                        </option>
                      </select>
                    </div>

                    <div class="col-12  col-sm-4 form-group">
                      <label for="construccionYear" class="label">
                        Año de construcción
                        <small class="text-muted">* Obligatorio</small>
                      </label>
                      <input
                        v-model="form.construccion_year"
                        type="number"
                        class="form-control"
                        id="construccionYear"
                        placeholder=""
                        required
                        min="1500"
                      />
                    </div>

                    <div class="col-12 col-sm-4 form-group">
                      <label for="autoridadResponsable" class="label">
                        Autoridad responsable
                        <small class="text-muted">* Obligatorio</small>
                      </label>
                      <input
                        v-model="form.autoridad_responsable"
                        type="text"
                        class="form-control"
                        id="autoridadResponsable"
                        placeholder=""
                        value=""
                        required
                        maxlength="50"
                      />
                    </div>

                    <div class="col-12 col-sm-4 form-group">
                      <label for="superficieHabitable" class="label">
                        Superficie habitable [m2]
                        <small class="text-muted">* Obligatorio</small>
                      </label>
                      <input
                        v-model="form.superficie_habitable"
                        type="number"
                        step="0.01"
                        class="form-control"
                        id="superficieHabitable"
                        placeholder=""
                        value=""
                        required
                      />
                    </div>

                    <div class="col-12 col-sm-4 form-group">
                      <label for="numeroDePlantas" class="label">
                        Número de plantas
                        <small class="text-muted">* Obligatorio</small>
                      </label>
                      <input
                        v-model="form.numero_de_plantas"
                        type="number"
                        class="form-control"
                        id="numeroDePlantas"
                        placeholder=""
                        value=""
                        required
                        max="99"
                      />
                    </div>

                    <div class="col-12 col-sm-4 form-group">
                      <label for="configuracion" class="label">
                        Configuración
                        <small class="text-muted">* Obligatorio</small>
                      </label>
                      <select
                        required
                        class="custom-select"
                        id="configuracion"
                        v-model="form.configuracion"
                      >
                        <option
                          v-for="(option, index) in configuracionArray"
                          :value="option.value"
                          :key="index"
                        >
                          {{ option.text }}
                        </option>
                      </select>
                    </div>

                    <div class="col-12 col-sm-4 form-group">
                      <label for="zonaClimatica" class="label">
                        Zona Climática
                        <small class="text-muted">* Obligatorio</small>
                      </label>
                      <select
                        required
                        class="custom-select"
                        id="zonaClimatica"
                        v-model="form.zona_climatica"
                      >
                        <option
                          v-for="(option, index) in zonaClimaticaArray"
                          :value="option"
                          :key="index"
                        >
                          {{ option }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- . Left -->
                <!-- Right -->
                <div class="col-sm-12 col-md-6">
                  <div class="row">
                    <div class="col-12 col-sm-6 form-group">
                      <label class="label">Imagen</label>
                      <div class="input-group mb-3">
                        <div class="custom-file">
                          <label class="custom-file-label " for="imagen"
                            >{{ this.imageName }}
                          </label>
                          <input
                            @change="handleImage"
                            type="file"
                            accept="image/*"
                            id="imagen"
                            class="imagenInput"
                            placeholder="Elige una imagen"
                          />
                        </div>
                      </div>
                      <img
                        class="imagenDisplay"
                        id="image-target"
                        :src="form.imagen"
                      />
                    </div>

                    <div class="col-sm-6 form-group">
                      <label class="label">Ubicación</label>
                      <div>
                        <GmapAutocomplete
                          class="form-control mb-1"
                          style="height: 40px"
                          @place_changed="setPlace"
                        ></GmapAutocomplete>
                        <div>
                          <small>
                            Coordenadas:
                            {{ myCoordinates.lat }} Latitud,
                            {{ myCoordinates.lng }} Longitud
                          </small>
                        </div>
                        <GmapMap
                          :center="myCoordinates"
                          :zoom="zoom"
                          class="map"
                          ref="mapRef"
                        >
                          <GmapMarker
                            :position="{
                              lat: myCoordinates.lat,
                              lng: myCoordinates.lng,
                            }"
                            :draggable="true"
                            @dragend="updateCoordinates"
                          ></GmapMarker>
                        </GmapMap>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- . Right -->
              </div>
              <div class="row">
                <div class="col-12">
                  <hr class="my-4" />

                  <!-- CERRAMIENTOS -->
                  <div class="row">
                    <div class="col-12 mb-3">
                      <h3>Cerramientos</h3>
                    </div>
                    <div class="col-12">
                      <div
                        v-for="(cerramiento, index) in cerramientosList"
                        :key="index"
                        class="row mt-2"
                      >
                        <div class="col-sm-3 form-group">
                          <label for="cerramientoSuperficie" class="label">
                            Superficie [m2]
                            <small class="text-muted">* Obligatorio</small>
                          </label>
                          <input
                            v-model="cerramiento.superficie"
                            type="number"
                            step="0.01"
                            class="form-control"
                            id="cerramientoSuperficie"
                            placeholder=""
                            value=""
                            required
                            min="0"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label for="cerramientoTipo" class="label">
                            Tipo <small class="text-muted">* Obligatorio</small>
                          </label>
                          <select
                            required
                            class="custom-select"
                            id="cerramientoTipo"
                            v-model="cerramiento.tipo"
                          >
                            <option
                              v-for="(option, index) in cerramientoTipoArray"
                              :value="option.value"
                              :key="index"
                            >
                              {{ option.text }}
                            </option>
                          </select>
                        </div>
                        <div class="col-sm-4 form-group">
                          <label for="cerramientoTransmitancia" class="label">
                            Transmitancia [W/m2K]
                            <small class="text-muted">* Obligatorio</small>
                          </label>
                          <input
                            v-model="cerramiento.transmitancia"
                            type="number"
                            step="0.01"
                            class="form-control"
                            id="cerramientoTransmitancia"
                            placeholder=""
                            value=""
                            required
                            max="999"
                            min="0"
                          />
                        </div>
                        <div class="col-sm-1 form-group">
                          <button
                            class="btn btn-danger button-margin"
                            @click.prevent="removeCerramiento(cerramiento)"
                          >
                            —
                          </button>
                        </div>
                      </div>
                      <button
                        class="btn btn-primary"
                        @click.prevent="addCerramiento"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <!-- . CERRAMIENTOS -->

                  <hr class="my-4" />

                  <!-- HUECOS -->
                  <div class="row">
                    <div class="col-12 mb-3">
                      <h3>Huecos</h3>
                    </div>
                    <div class="col-12">
                      <div
                        v-for="(hueco, index) in huecosList"
                        :key="index"
                        class="row mt-2"
                      >
                        <div class="col-sm-3 form-group">
                          <label for="huecoSuperficie" class="label">
                            Superficie [m2]
                            <small class="text-muted">* Obligatorio</small>
                          </label>
                          <input
                            v-model="hueco.superficie"
                            type="number"
                            step="0.01"
                            class="form-control"
                            id="huecoSuperficie"
                            placeholder=""
                            value=""
                            required
                            min="0"
                          />
                        </div>
                        <div class="col-sm-4 form-group">
                          <label for="huecoTipo" class="label">
                            Tipo <small class="text-muted">* Obligatorio</small>
                          </label>
                          <select
                            required
                            class="custom-select"
                            id="huecoTipo"
                            v-model="hueco.tipo"
                          >
                            <option
                              v-for="(option, index) in huecoTipoArray"
                              :value="option.value"
                              :key="index"
                            >
                              {{ option.text }}
                            </option>
                          </select>
                        </div>
                        <div class="col-sm-4 form-group">
                          <label for="huecoTransmitancia" class="label">
                            Transmitancia [W/m2K]
                            <small class="text-muted">* Obligatorio</small>
                          </label>
                          <input
                            v-model="hueco.transmitancia"
                            type="number"
                            step="0.01"
                            class="form-control"
                            id="huecoTransmitancia"
                            placeholder=""
                            value=""
                            required
                            max="999"
                            min="0"
                          />
                        </div>
                        <div class="col-sm-1 form-group">
                          <button
                            class="btn btn-danger button-margin"
                            @click.prevent="removeHueco(hueco)"
                          >
                            —
                          </button>
                        </div>
                      </div>
                      <button class="btn btn-primary" @click.prevent="addHueco">
                        +
                      </button>
                    </div>
                  </div>
                  <!-- . HUECOS -->

                  <div class="row">
                    <div class="col-12 mt-2 text-center">
                      <small class="text-danger" v-if="this.submitError">
                        Se ha producido un error. Por favor, revise los datos.
                      </small>
                      <br /><br />

                      <button class="btn btn-primary" type="submit">
                        Guardar y continuar
                      </button>

                      <button
                        class="btn btn-danger ml-5"
                        type="button"
                        v-show="isEditing"
                        @click="deleteBuilding"
                      >
                        Borrar edificio
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from "@/api.js";

export default {
  name: "CreateBuildingForm",
  data: () => ({
    isEditing: false,

    buildingId: null,
    place: null,
    imageName: "",
    form: {
      referencia_catastral: undefined,
      denominacion: undefined,
      localidad: undefined,
      uso: undefined,
      construccion_year: undefined,
      autoridad_responsable: undefined,

      ubicacion: null,

      imagen: null,
      superficie_habitable: null,
      numero_de_plantas: null,
      configuracion: null,
      zona_climatica: null,
      superficie_envolvente: null,
      porcentaje_huecos: null,
      factor_forma: null,
    },

    map: null,
    myCoordinates: {
      lat: 0,
      lng: 0,
    },
    zoom: 7,

    cerramientosArray: [],

    huecosArray: [],

    usoArray: [
      {
        value: "DOCENTE",
        text: "Docente",
      },
      {
        value: "ADMINISTRATIVO",
        text: "Administrativo",
      },
      {
        value: "SANITARIO",
        text: "Sanitario",
      },
      {
        value: "DEPORTIVO",
        text: "Deportivo",
      },
      {
        value: "OCIO / ESPECTÁCULO",
        text: "Ocio / Espectáculo",
      },
      {
        value: "COMERCIAL",
        text: "Comercial",
      },
      {
        value: "INDUSTRIAL",
        text: "Industrial",
      },
      {
        value: "CULTURAL",
        text: "Cultural",
      },
    ],
    configuracionArray: [
      {
        value: "EDIFICO COMPLETO ENTREMEDIANERO",
        text: "Edificio completo entremedianero",
      },
      {
        value: "EDIFICO COMPLETO EXENTO",
        text: "Edificio completo exento",
      },
      {
        value: "LOCAL O APARTAMENTO",
        text: "Local o apartamento",
      },
    ],
    zonaClimaticaArray: [
      "A4",
      "A3",
      "B4",
      "B3",
      "C4",
      "C3",
      "C2",
      "C1",
      "D3",
      "D2",
      "D1",
      "E1",
    ],
    cerramientoTipoArray: [
      {
        value: "CUBIERTA",
        text: "Cubierta",
      },
      {
        value: "FACHADA",
        text: "Fachada",
      },
      {
        value: "SUELO",
        text: "Suelo",
      },
      {
        value: "INTERIOR",
        text: "Partición Interior",
      },
    ],

    huecoTipoArray: [
      {
        value: "HUECO",
        text: "Hueco",
      },
      {
        value: "LUCERNARIO",
        text: "Lucernario",
      },
    ],

    submitError: false,
  }),

  async mounted() {
    // add the map to a data object
    this.$refs.mapRef.$mapPromise.then((map) => (this.map = map));

    if (this.$route.params.id) {
      this.isEditing = true;
      this.buildingId = this.$route.params.id;
      await this.getBuilding();
      await this.getCerramientos();
      await this.getHuecos();
    }

    if (!this.form.ubicacion) {
      this.$getLocation({})
        .then((coordinates) => {
          this.myCoordinates = coordinates;
        })
        .catch((error) => {
          // alert(error)
          console.log(error);
        });
      // }
    } else {
      this.myCoordinates = {
        lat: Number(this.form.ubicacion.latitud),
        lng: Number(this.form.ubicacion.longitud),
      };
    }
  },

  computed: {
    cerramientosList() {
      return this.cerramientosArray.filter((item) => {
        return !item.deleted;
      });
    },
    huecosList() {
      return this.huecosArray.filter((item) => {
        return !item.deleted;
      });
    },
  },

  methods: {
    setPlace(place) {
      this.place = place;
      this.myCoordinates = {
        lat: Number(this.place.geometry.location.lat().toFixed(4)),
        lng: Number(this.place.geometry.location.lng().toFixed(4)),
      };
      console.log(this.myCoordinates);
    },

    updateCoordinates(location) {
      this.myCoordinates = {
        lat: Number(location.latLng.lat().toFixed(4)),
        lng: Number(location.latLng.lng().toFixed(4)),
      };
    },

    async handleImage(e) {
      const selectedImage = e.target.files[0];
      this.form.imagen = selectedImage;
      this.imageName = this.form.imagen.name;

      // Necesario para mostrar imagen al seleccionarla
      var src = document.getElementById("imagen");
      var target = document.getElementById("image-target");
      this.showImage(src, target);
    },

    // Método para mostrar imagen al seleccionarla
    showImage(src, target) {
      var fr = new FileReader();

      fr.onload = function() {
        target.src = fr.result;
      };
      fr.readAsDataURL(src.files[0]);
    },

    async getBuilding() {
      const { data } = await api.get(`buildings/${this.$route.params.id}`);
      this.form = data;
      console.log(this.form.imagen);
    },

    // CERRAMIENTOS
    async getCerramientos() {
      const { data } = await api.get(
        `cerramientos/?building=${this.buildingId}`
      );

      this.cerramientosArray = data;
    },

    addCerramiento() {
      this.cerramientosArray.push({
        superficie: undefined,
        tipo: undefined,
        transmitancia: undefined,
      });
    },
    removeCerramiento(cerramiento) {
      this.$set(cerramiento, "deleted", true);
      this.$set(this.cerramientosArray, cerramiento);
    },

    async submitCerramientos() {
      const postCerramientosList = this.cerramientosArray.filter((item) => {
        return !item.id && !item.deleted;
      });
      const putCerramientosList = this.cerramientosArray.filter((item) => {
        return item.id && !item.deleted;
      });
      const deleteCerramientosList = this.cerramientosArray.filter((item) => {
        return item.id && item.deleted;
      });

      postCerramientosList.forEach(async (item) => {
        if (item.superficie && item.tipo && item.transmitancia) {
          item["building"] = this.buildingId;
          await api.post("cerramientos/", item);
        }
      });

      putCerramientosList.forEach(async (item) => {
        if (item.superficie && item.tipo && item.transmitancia) {
          item["building"] = this.buildingId;
          await api.put(`cerramientos/${item.id}/`, item);
        }
      });

      deleteCerramientosList.forEach(async (item) => {
        await api.delete(`cerramientos/${item.id}/`);
      });
    },

    // HUECOS
    async getHuecos() {
      const { data } = await api.get(`huecos/?building=${this.buildingId}`);

      this.huecosArray = data;
    },

    addHueco() {
      this.huecosArray.push({
        superficie: undefined,
        tipo: undefined,
        transmitancia: undefined,
      });
    },

    removeHueco(hueco) {
      this.$set(hueco, "deleted", true);
      this.$set(this.huecosArray, hueco);
    },

    async submitHuecos() {
      const postHuecosList = this.huecosArray.filter((item) => {
        return !item.id && !item.deleted;
      });
      const putHuecosList = this.huecosArray.filter((item) => {
        return item.id && !item.deleted;
      });
      const deleteHuecosList = this.huecosArray.filter((item) => {
        return item.id && item.deleted;
      });

      postHuecosList.forEach(async (item) => {
        if (item.superficie && item.tipo && item.transmitancia) {
          item["building"] = this.buildingId;
          await api.post("huecos/", item);
        }
      });

      putHuecosList.forEach(async (item) => {
        if (item.superficie && item.tipo && item.transmitancia) {
          item["building"] = this.buildingId;
          await api.put(`huecos/${item.id}/`, item);
        }
      });

      deleteHuecosList.forEach(async (item) => {
        await api.delete(`huecos/${item.id}/`);
      });
    },

    // SUBMIT
    async submitEnvelope() {
      await this.submitHuecos();
      await this.submitCerramientos();

      this.cerramientosArray = this.cerramientosList;
      this.huecosArray = this.huecosList;
    },

    getUbication() {
      return JSON.stringify({
        latitud: String(this.myCoordinates.lat),
        longitud: String(this.myCoordinates.lng),
      });
    },

    parseForm() {
      this.form.ubicacion = this.getUbication();

      const formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        if (this.form[key]) formData.append(key, this.form[key]);
      });

      if (typeof formData.get("imagen") === "string") {
        formData.delete("imagen");
      }

      return formData;
    },

    async submitBuilding() {
      const parsedForm = this.parseForm();

      const { data } = await api.post("buildings/", parsedForm, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.buildingId = data.id;
    },

    async editBuilding() {
      const parsedForm = this.parseForm();

      await api.put(`buildings/${this.$route.params.id}/`, parsedForm, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },

    async submitGlobal() {
      try {
        if (this.isEditing) {
          await this.editBuilding();
        } else {
          await this.submitBuilding();
        }
        await this.submitEnvelope();

        this.$router.push({ name: "energy", params: { id: this.buildingId } });
      } catch {
        this.submitError = true;
      }
    },

    async deleteBuilding() {
      try {
        await api.delete(`buildings/${this.buildingId}/`);
        this.$router.push({ name: "listBuildings" });
      } catch {
        this.submitError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.imagenInput {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.imagenDisplay {
  width: 100%;
  max-height: 350px;
  object-fit: contain;
}
.map {
  width: 100%;
  height: 300px;
}

.button-margin {
  margin-top: 2rem;
}
</style>
